<template>
  <div class="job-apply login-page">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
          <form @submit.prevent="submit()" class="job-apply-section shadow">
            <div class="row g-3">
              <div class="login-header mb-4 pb-3 text-center">
                <div class="login-logo mb-4">
                  <img src="/assets/loginLogo.svg" alt="">
                </div>
                <h4 class="login-title"><b>Hey, Good to see you again! </b></h4>
                <p>Log in to get going with our recruitment process!</p>
              </div>
              <span v-if="form_errors.hasOwnProperty('detail')" class="error-msg message-error text-center">
                {{ form_errors.detail }}
              </span>
              <div class="col-md-12">
                <label class="form-label" for="email">Email Address</label>
                <input type="text" id="email" v-model="credentials.email"
                       class="form-control"
                       placeholder="email@mail.com" name="email">
                <span v-if="form_errors.hasOwnProperty('email')" class="error-msg">{{ form_errors.email[0] }}</span>
              </div>
              <div class="col-md-12 mb-5">
                <label class="form-label" for="password">Password</label>
                <div class="input-group">
                  <PasswordField v-model="credentials.password" placeholder="Password"/>
                </div>
                <span v-if="form_errors.hasOwnProperty('password')" class="error-msg">
                  {{ form_errors.password[0] }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <button type="submit" class="login-btn">
                Submit
              </button>
              <router-link to="/forgot-password" class="text-center forgot-password d-block mt-3">
                <u>Forgot password</u>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import PasswordField from "@/components/PasswordField";


export default {
  name: "Login",
  data: () => ({
    credentials: {
      email: '',
      password: ''
    },
    form_errors: {}
  }),
  components: {
    PasswordField
  },

  methods: {
    submit() {
      this.login();
    },

    async login() {
      await axios.post('/login/', this.credentials).then(res => {

        this.$store.commit('SET_TOKEN', res.data._token)
      }).catch(err => {
        this.form_errors = err.response.data
      })
      await this.setActiveUser()
    },

    async setActiveUser() {
      await axios.get('/candidate/').then(res => {
        this.$store.commit('UPDATE_USER', res.data)
        if (this.$route.query.nextUrl != null) {

          this.$router.push("job/"+this.$route.query.nextUrl)
        } else {
          this.$router.push({name: 'Dashboard'})
        }
      })
    },
    showError(errorType) {
      let errors = null;
      for (let error in this.errorMessage) {
        if (error == errorType) {
          errors = this.errorMessage[error];
          break;
        }
      }
      return errors;
    }
  }
}
</script>

<style scoped>

</style>