<template>
  <div class="input-group">
    <input :type="input_type" :value="value" @input="handleInput($event.target.value)" :placeholder="placeholder"
           class="form-control">
    <span @click="toggleInputType" class="input-group-text">
      <i class="fas" :class="input_type === 'text' ? 'fa-eye' : 'fa-eye-slash'"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "PasswordField",
  props: ['value', 'placeholder'],
  data() {
    return {
      input_type: 'password'
    }
  },
  methods: {
    toggleInputType() {
      if (this.input_type === 'password') {
        this.input_type = 'text'
      } else {
        this.input_type = 'password'
      }
    },

    handleInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>